import React from 'react'
import Layout from '../layouts'
import { Link } from 'gatsby'

const ThankYou = () => (
	<Layout
		pathname="/thank-you/"
		head={{
			name: 'Thank You',
			meta: {
				description:
					'Thank you for reaching out to us. We have received your message and will get back to you soon. We have received your message and will get back to you soon.',
			},
		}}
	>
		<div className="container py-5">
			<div className="row">
				<div className="col-12">
					<div className="page-content py-5 my-5">
						<center>
							<h1>Thank You!</h1>
							<p>For reaching out to us.</p>
							<p>
								We have received your message and will get back to you soon.
							</p>
							<Link
								to="/"
								title="Back to home"
								className="btn btn-primary blue-btn-arrow mt-3 text-white"
							>
								<strong>BACK TO HOME</strong>
								<i className="ml-2 fa fa-arrow-right" />
							</Link>
						</center>
					</div>
				</div>
			</div>
		</div>
	</Layout>
)

export default ThankYou
